/* eslint-disable import/prefer-default-export */
import { GridColDef } from '@mui/x-data-grid-pro';
import renderCellExpand from '../../../components/Datagrid/DatagridPopover';
import DatagridTooltip from '../../../components/Datagrid/DatagridTooltip';
import DatagridArrayListView from '../../../components/Datagrid/DatagridArrayListView';
import { getFormatDataForCSV } from '../../ResultsPage/utils/getFormatDataForCSV';

export const US_PRODUCT_COLUMN_DEFINITION: GridColDef[] = [
  {
    field: 'product_number',
    headerName: 'Product Number',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'strength',
    headerName: 'Strength',
    headerClassName: 'table-header',
    width: 250,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'reference_drug',
    headerName: 'Reference Listed Drug',
    headerClassName: 'table-header',
    width: 250,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'reference_standard',
    headerName: 'Reference Standard',
    headerClassName: 'table-header',
    width: 250,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'te_code',
    headerName: 'Therapeautic Equivalence',
    headerClassName: 'table-header',
    width: 280,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  },
  {
    field: 'marketing_status_description',
    headerName: 'Marketing Status',
    headerClassName: 'table-header',
    width: 250,
    cellClassName: 'table-row',
    renderCell: DatagridTooltip
  }
];
export const EU_PRODUCT_COLUMN_DEFINITION: GridColDef[] = [
  {
    field: 'product_number',
    headerName: 'Product Number',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row'
  },
  {
    field: 'strength',
    headerName: 'Strength',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row'
  },
  {
    field: 'concentration',
    headerName: 'Concentration',
    headerClassName: 'table-header',
    width: 230,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'formulation',
    headerName: 'Formulation',
    headerClassName: 'table-header',
    width: 230,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'packaging',
    headerName: 'Packaging',
    headerClassName: 'table-header',
    width: 230,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'packsize',
    headerName: 'Pack Size',
    headerClassName: 'table-header',
    width: 230,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'route',
    headerName: 'Route',
    headerClassName: 'table-header',
    width: 230,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  }
];

export const JP_PRODUCT_COLUMN_DEFINITION: GridColDef[] = [
  {
    field: 'yj_code',
    headerName: 'Product Code',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row'
  },
  {
    field: 'trade_name',
    headerName: 'Trade Name',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'route',
    headerName: 'Route',
    headerClassName: 'table-header',
    width: 230,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'formulation',
    headerName: 'Formulation',
    headerClassName: 'table-header',
    width: 230,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'other_sales_name',
    headerName: 'Other Sales Name',
    headerClassName: 'table-header',
    width: 230,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => {
      const returnVal: string[] = [];
      if (row.other_sales_name && row.other_sales_name.length > 0) {
        Object.entries(row.other_sales_name).forEach(([key, value]) => {
          if (value && key) {
            const splitKey = key.split('_');
            const newKey = splitKey.length > 0 ? splitKey[splitKey.length - 1] : splitKey;
            returnVal.push(`${newKey}: ${value}`);
          }
        });
      }
      if (returnVal.length > 0) {
        return returnVal.join(', ');
      }
      return 'N/A';
    },
    renderCell: renderCellExpand
  },
  {
    field: 'price',
    headerName: 'Price',
    headerClassName: 'table-header',
    width: 230,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => (row?.price ? `${row.price} (¥)` : 'Not Available'),
    renderCell: renderCellExpand
  },
  {
    field: 'latest_submission_date',
    headerName: 'Latest Submission Date',
    headerClassName: 'table-header',
    width: 230,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  }
];
export const CA_PRODUCT_COLUMN_DEFINITION: GridColDef[] = [
  {
    field: 'din',
    headerName: 'DIN Number',
    headerClassName: 'table-header',
    width: 250,
    cellClassName: 'table-row'
  },
  {
    field: 'active_ingredients',
    headerName: 'Active Ingredients',
    headerClassName: 'table-header',
    width: 250,
    cellClassName: 'table-row',
    valueGetter: ({ row }) => `${row.active_ingredients[0].name}`,
    renderCell: DatagridTooltip
  },
  {
    field: 'latest_submission_w_label',
    headerName: 'Latest Submission Number',
    headerClassName: 'table-header',
    width: 250,
    cellClassName: 'table-row'
  },
  {
    field: 'current_status',
    headerName: 'Current Status',
    headerClassName: 'table-header',
    width: 300,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'indications_and_usage',
    headerName: 'Indications and Usage',
    headerClassName: 'table-header',
    width: 300,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'Strength',
    headerName: 'Strength',
    headerClassName: 'table-header',
    width: 250,
    cellClassName: 'table-row',
    valueGetter: ({ row }) =>
      `${row.active_ingredients[0].strength}${row.active_ingredients[0].strength_unit}/${row.active_ingredients[0].dosage_unit}`,
    renderCell: renderCellExpand
  },
  {
    field: 'atc_code',
    headerName: 'ATC Code',
    headerClassName: 'table-header',
    width: 230,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'route',
    headerName: 'Route',
    headerClassName: 'table-header',
    width: 230,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'formulation',
    headerName: 'Formulation',
    headerClassName: 'table-header',
    width: 230,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'schedule',
    headerName: 'Schedule',
    headerClassName: 'table-header',
    width: 230,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'first_approval_date',
    headerName: 'First Approval Date',
    headerClassName: 'table-header',
    width: 230,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'first_market_date',
    headerName: 'First Market Date',
    headerClassName: 'table-header',
    width: 230,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  }
  // diasble for temporary
  // {
  //   field: 'approval_tracks',
  //   headerClassName: 'table-header',
  //   headerName: 'Approval Tracks',
  //   width: 240,
  //   cellClassName: 'table-row',
  //   renderCell: DatagridAvatars,
  //   disableExport: true
  // }
];

export const HPRA_PRODUCT_COLUMN_DEFINITION: GridColDef[] = [
  {
    field: 'product_number',
    headerName: 'Product Number',
    headerClassName: 'table-header',
    width: 210,
    cellClassName: 'table-row'
  },
  {
    field: 'approval_date',
    headerName: 'First Approval Date',
    headerClassName: 'table-header',
    width: 220,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'formulation',
    headerName: 'Formulation',
    headerClassName: 'table-header',
    width: 220,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'route',
    headerName: 'Route',
    headerClassName: 'table-header',
    width: 220,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'marketing_status',
    headerName: 'Marketing Status',
    headerClassName: 'table-header',
    width: 220,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'advertising_status',
    headerName: 'Advertising Status',
    headerClassName: 'table-header',
    width: 330,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'supply_status',
    headerName: 'Supply Status',
    headerClassName: 'table-header',
    width: 300,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  }
];

export const HMA_PRODUCT_COLUMN_DEFINITION: GridColDef[] = [
  {
    field: 'product_number',
    headerName: 'Product Number',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row'
  },
  {
    field: 'approval_date',
    headerName: 'First Approval Date',
    headerClassName: 'table-header',
    width: 180,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'submission_category',
    headerName: ' Submission Category',
    headerClassName: 'table-header',
    width: 300,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'submission_type',
    headerName: ' Submission Type',
    headerClassName: 'table-header',
    width: 220,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'submission_class',
    headerName: ' Submission Class',
    headerClassName: 'table-header',
    width: 220,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'rm_state',
    headerName: ' Reference Member State',
    headerClassName: 'table-header',
    width: 220,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'cm_states',
    headerName: 'Concerned Member State',
    headerClassName: 'table-header',
    width: 220,
    cellClassName: 'table-row',
    disableExport: true,
    renderCell: params => DatagridArrayListView(params, 'state_name')
  },
  {
    field: 'formulation',
    headerName: 'Formulation',
    headerClassName: 'table-header',
    width: 320,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'marketing_status',
    headerName: 'Marketing Status',
    headerClassName: 'table-header',
    width: 220,
    cellClassName: 'table-row',
    renderCell: renderCellExpand
  },
  {
    field: 'cm_states_csv',
    headerName: 'Concerned Member State',
    headerClassName: 'table-header',
    width: 220,
    cellClassName: 'table-row',
    valueGetter: ({ row }) =>
      `${row.cm_states && row.cm_states.length !== 0 ? getFormatDataForCSV(row.cm_states) : ''}`
  }
];

export const PRODUCT_DETAILS_COLUMN_DEFINITION: { [key: string]: any } = {
  us: US_PRODUCT_COLUMN_DEFINITION,
  eu: EU_PRODUCT_COLUMN_DEFINITION,
  ca: CA_PRODUCT_COLUMN_DEFINITION,
  hpra: HPRA_PRODUCT_COLUMN_DEFINITION,
  hma: HMA_PRODUCT_COLUMN_DEFINITION,
  jp: JP_PRODUCT_COLUMN_DEFINITION
};

export const PRODUCT_ROW_ID_MAP: { [key: string]: any } = {
  ca: 'din',
  jp: 'yj_code',
  default: 'product_number'
};
